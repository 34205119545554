import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "25",
  viewBox: "0 0 24 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "quote" }, [
      _createElementVNode("path", {
        id: "Vector",
        d: "M9.2511 19.666H0L5.9207 5.66602H12L9.2511 19.666ZM21.2511 19.666H12.0529L17.9736 5.66602H24L21.2511 19.666Z",
        fill: "#F06F02"
      })
    ], -1)
  ])))
}
export default { render: render }